import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import WorkCard from './workCard';
import styled from 'styled-components';
import { RoundedButton } from '../components/components';

const PageContainer = styled.div`
  width: 100%;
`;

interface IPage {
  data: Array<any>;
  size: number;
  hideButton?: boolean;
}
const Page = ({ data, size, hideButton = false }: IPage) => {
  const [allNews, setAllNews] = useState(data);

  const [list, setList] = useState([...allNews.slice(0, size)]);

  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(allNews.length > size);

  useEffect(() => {
    setAllNews(data);
    setList(data.slice(0, size));
  }, []);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const use = () => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < data.length;
      const nextResults = isMore ? data.slice(currentLength, currentLength + size) : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  };
  useEffect(() => {
    use();
  }, [loadMore, hasMore]);

  useEffect(() => {
    const isMore = list.length < data.length;
    setHasMore(isMore);
  }, [list]);

  return (
    <PageContainer className="custom-container">
      <div className="container-fluid g-0">
        {list.map((article, index) => (
          <WorkCard
            key={index}
            image={`${article.node.listIntro.image}`}
            title={`${article.node.listIntro.title}`}
            technologies={`${article.node.technologies}`}
            text={`${article.node.listIntro.description}`}
            link={article.node.slug}
            buttonTitle={article.node.titleForButton}
            altText={article.node.listIntro.altText}
          />
        ))}
      </div>
      <Fade bottom>
        <div className="text-center d-block">
          {!hideButton && hasMore && (
            <RoundedButton
              className="btn-white"
              onClick={handleLoadMore}
              title="Load more projects"
            >
              View more projects
            </RoundedButton>
          )}
        </div>
      </Fade>
    </PageContainer>
  );
};
export default Page;
