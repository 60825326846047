import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/backgroundSection';
import {
  Section,
  Card,
  Title,
  Text,
  TitleOutline,
  SectionHeaderTitle,
  LettersAnimation,
  HeaderAnimation,
  HeadingType,
  Variant,
  ButtonLink,
  List,
} from '../components/components';
import styled from 'styled-components';
import Pagination from '../components/Pagination';
import usePosition from '../components/usePosition';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Image, { ImageType } from '../components/image';
import Carousel, { ICarousel } from '../components/carousel';

const SectionClipMask = styled.div`
  position: relative;
  height: auto;
  z-index: 0;

  .section-image {
    background-position: right;
    background-repeat: no-repeat;
    position: relative;
  }
  .section-content {
    display: flex;

    align-items: start;
    flex-flow: column;
    justify-content: center;
  }
  @media (max-width: 767px) {
    .section-content {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
const CustomSection = styled(Section)`
  .max-width-880 {
    max-width: 880px;
  }
  .max-width-680 {
    max-width: 680px;
  }
  @media (min-width: 992px) {
    .offset-lg-0 {
      margin-left: 0px !important;
    }
  }
`;
const CustomTitle = styled(Title)`
  padding-bottom: 24px;
  @media (min-width: 768px) {
    &,
    .fit-content {
      // font-weight: bold;
      // font-size: 48px;
      // line-height: 58px;
      // letter-spacing: -0.01em;
    }
  }
  @media (max-width: 767px) {
    &,
    .fit-content {
      // font-family: Kollektif;
      // font-style: normal;
      // font-weight: bold;
      // font-size: 36px;
      // line-height: 43px;
      // letter-spacing: -0.01em;
    }
  }
  .fit-content {
    width: fit-content !important;
    display: inline;
    padding-left: 0.2em;
  }
  @media (min-width: 992px) {
    .fit-content {
      display: block;
    }
  }
`;
const ButtonContainer = styled.div`
  .btn-link {
    width: 250px;
  }
`;
const servicesData: ICarousel[] = [
  {
    title: 'Web solutions',
    description: (
      <List paddingTop="0px" className="list-dashed">
        <li>Websites - like this one!</li>
        <li>Store shopping carts.</li>
        <li>Interactive online experiences.</li>
      </List>
    ),
    image: 'home-services-web@2x.png',
    button: (
      <ButtonLink to="/contacts/" className="btn-purple fit-content" title="Contact Us">
        Build your website today!
      </ButtonLink>
    ),
    altTextImage: 'Web solutions',
  },
  {
    title: 'Apps',
    description: (
      <List paddingTop="0px" className="list-dashed">
        <li>Want to build the next Uber of X? Let's do it.</li>
        <li>Tinder for Dogs? Absolutely.</li>
        <li>What is this, an Instagram for ants?</li>
      </List>
    ),
    image: 'home-services-apps@2x.png',
    button: (
      <ButtonLink to="/contacts/" className="btn-purple fit-content" title="Contact Us">
        Build your website today!
      </ButtonLink>
    ),
    altTextImage: 'Apps',
  },
  {
    title: 'Games',
    description: (
      <List paddingTop="0px" className="list-dashed">
        <li>Mobile games.</li>
        <li>VR Experiences.</li>
        <li>Web-based online multi-player games.</li>
      </List>
    ),
    image: 'home-services-vr@2x.png',
    button: (
      <ButtonLink to="/contacts/" className="btn-purple fit-content" title="Contact Us">
        Build your website today!
      </ButtonLink>
    ),
    altTextImage: 'Games',
  },
];

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allAppsMobileGamesJson {
        edges {
          node {
            slug
            category
            titleForButton
            technologies
            projectName
            viewOrder
            listIntro {
              image
              altText
              title
              description
            }
          }
        }
      }
      allVrGamesJson {
        edges {
          node {
            slug
            category
            titleForButton
            technologies
            projectName
            viewOrder
            listIntro {
              image
              altText
              title
              description
            }
          }
        }
      }
      allWebJson {
        edges {
          node {
            slug
            category
            titleForButton
            technologies
            projectName
            viewOrder
            listIntro {
              image
              altText
              title
              description
            }
          }
        }
      }
    }
  `);

  const { scrollPosition } = usePosition();
  const projects = [
    ...data.allWebJson.edges,
    ...data.allVrGamesJson.edges,
    ...data.allAppsMobileGamesJson.edges,
  ].sort(function (a, b) {
    return a.node.viewOrder - b.node.viewOrder;
  });
  return (
    <Layout
      navbarBackground={`${scrollPosition > 730 ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}`}
      navbarColor={`${scrollPosition > 730 ? '#3C4356' : 'white'}`}
    >
      <SEO title="Home" />
      <Section
        padding="0px 0px"
        mobilePadding="0px 0px"
        tabletPadding="0px 0px"
        bgColor="linear-gradient(134deg, #5b26bf 0%, #5096f4 100%)"
        className="position-relative custom-container"
      >
        <SectionHeaderTitle className="row g-0 gutt-16">
          <div className="col-8 col-md-7 col-lg-8 d-flex flex-wrap justify-content-center">
            <Title
              heading={HeadingType.H1}
              font="Kollektif"
              weight="700"
              color="white"
              align="center"
              type="shadow"
              desktopsize="82px"
              mobilesize="62px"
              className="d-flex flex-wrap justify-content-center"
            >
              Bring us your idea
            </Title>
            <Title
              heading={HeadingType.H2}
              font="Kollektif"
              weight="400"
              desktopsize="62px"
              mobilesize="48px"
              color="white"
              align="center"
              type="shadow"
              className="d-flex flex-wrap justify-content-center"
            >
              We will help you do the rest
            </Title>
            <ButtonContainer className="mt-5 d-flex justify-content-center">
              <ButtonLink to="/contacts/" className="btn-white fit-content" title="Contact us">
                Let’s talk about your project!
              </ButtonLink>
            </ButtonContainer>
          </div>
        </SectionHeaderTitle>
        <HeaderAnimation cookieKey="home-header-loaded">
          <Hero fileName="image-home-cover@2x.jpg" ariaLabel="Bring us your idea" />
          <Hero style={{ background: '#0000004D', position: 'absolute', top: '0' }} />
        </HeaderAnimation>
      </Section>
      <CustomSection
        padding="60px 0%"
        mobilePadding="80px 0px 0px 0px"
        className="overflow-hidden custom-container outtl-32"
      >
        <SectionClipMask className="row g-0">
          <Fade bottom>
            <div className="col-8 col-md-6 offset-md-1 col-lg-4 section-content">
              <div>
                <CustomTitle
                  heading={HeadingType.H2}
                  desktopsize="62px"
                  tabletsize="48px"
                  mobilesize="36px"
                  lineheightdesktop="74.4px"
                  lineheighttablet="57.6px"
                  lineheightmobile="43.2px"
                  color="#222"
                >
                  We're looking to grant your
                  <Title
                    variant={Variant.Gradient}
                    desktopsize="62px"
                    tabletsize="48px"
                    mobilesize="36px"
                    lineheightdesktop="74.4px"
                    lineheighttablet="57.6px"
                    lineheightmobile="43.2px"
                    className="my-3 fit-content pr-0 m-0 "
                  >
                    DREAMS
                  </Title>
                </CustomTitle>
              </div>
              <Text size="16px">
                At Envibe we are a team of developers, designers and people who want to make a
                better world for others through technology.
              </Text>
              <Text size="16px">
                We seek to be flexible and open as people and professionals and we will be happy to
                help you build that dream project that you want so much.
              </Text>
              <div className="mt-5 d-flex justify-content-start">
                <ButtonLink to="/contacts/" className="btn-purple fit-content" title="Contact us">
                  Let’s talk about your project!
                </ButtonLink>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="col-lg-7 section-image d-none d-lg-block px-0">
              <Image
                src="mask-image.png"
                alt="We're looking to grant your
                dreams"
                type={ImageType.Fluid}
                className="m-auto"
              />
            </div>
          </Fade>
          <Fade>
            <div className="col-8 d-lg-none " style={{ paddingTop: 135 }}>
              <Image
                src="home-intro@2x.png"
                alt="We're looking to grant your
                dreams"
                type={ImageType.Fluid}
                className="m-auto"
              />
            </div>
          </Fade>
        </SectionClipMask>
      </CustomSection>

      <CustomSection
        padding="60px 0px"
        mobilePadding="0px 0px 20px 0px"
        className="custom-container outt-32"
      >
        <div className="row justify-content-center g-0">
          <Card className=" col-8 col-lg-10">
            <Fade bottom>
              <div className="col-md-6 offset-md-1 col-lg-12 offset-lg-0">
                <Title
                  desktopsize="62px"
                  tabletsize="48px"
                  mobilesize="36px"
                  lineheightdesktop="74.4px"
                  lineheighttablet="57.6px"
                  lineheightmobile="43.2px"
                  align="center"
                  className="m-auto max-width-880"
                >
                  Hand-Crafted projects for a wide range of needs
                </Title>
                <Text size="16px" lineheight="22px" className="pt-4 m-auto max-width-680">
                  Usually everything starts with a call or an email, where we provide basic
                  information about ourselves and how we work and ask some questions, but above all
                  we listen. Once we start working together on your project, we will meet many times
                  to gather information about the product, about the goals, the users, and many
                  other things.
                </Text>
                <div className="pt-5 d-flex justify-content-center">
                  <ButtonLink
                    to="/about/#our-process"
                    className="btn-white fit-content"
                    title="About page: see our process"
                  >
                    Discover our process
                  </ButtonLink>
                </div>
              </div>
            </Fade>
          </Card>
        </div>
      </CustomSection>

      <Section padding="25px 0px" mobilePadding="10px 0px" className="overflow-hidden">
        <LettersAnimation>
          <TitleOutline text={['WEB', 'APPS', 'GAMES']} count={3} />
        </LettersAnimation>
      </Section>
      <Carousel data={servicesData} />
      <Section
        padding="25px 0px"
        mobilePadding="60px 0px 20px 0px"
        tabletPadding="60px 0px 20px 0px"
        className="overflow-hidden"
      >
        <LettersAnimation>
          <TitleOutline text={['PROJECTS']} count={5} />
        </LettersAnimation>
      </Section>
      <Section
        padding="0px 0px 104px 0px"
        mobilePadding="20px 0px 144px 0px"
        tabletPadding="0px 0px 58px 0px"
        className="custom-container"
      >
        <Pagination data={projects} size={5} hideButton={true} />
        <div className="pt-5  d-flex justify-content-center">
          <ButtonLink to="/projects/" className="btn-white" title="Projects">
            View more projects
          </ButtonLink>
        </div>
      </Section>
    </Layout>
  );
};

export default IndexPage;
