import React, { createElement, useState, useEffect, useRef } from 'react';
import { Title, Text, Row, TitleHighlighted, ButtonLink } from '../components/components';
import styled from 'styled-components';
import useOnScreen from './useOnScreen';
import SVGImage from './SVGImage';
import Image, { ImageType } from '../components/image';

export const WorkCardStyled = styled(Row)`
  margin: 0px;
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
  min-height: 580px;

  &:nth-of-type(even) {
    padding-left: 8px;
  }
  &:nth-of-type(odd) {
    padding-right: 8px;
  }
  .section-animation {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .work-section-img {
    z-index: 0;
    padding-left: 0px;
    padding-right: 8px;
  }

  .work-section-img.active {
    -webkit-animation-name: fadeInLeftCard;
    animation-name: fadeInLeftCard;
    animation-duration: 2s;
    transition: opacity 2.1s;
    opacity: 1;
  }

  .work-section-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .work-section-content .max-width-440 {
    display: flex;
    flex-flow: column;
  }
  .work-section-content.active {
    -webkit-animation-name: fadeInRightCard;
    animation-name: fadeInRightCard;
    animation-duration: 2s;
    transition: opacity 2.1s;
    opacity: 1;
  }

  .work-icon-section {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .work-icon {
    padding-right: 10px;
  }

  .work-section-content a {
    display: block;
    margin: 0 auto;
  }
  @keyframes fadeInRightCard {
    from {
      opacity: 0;
      -webkit-transform: translateX(10%);
      transform: translateX(10%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes fadeInLeftCard {
    from {
      opacity: 0;
      -webkit-transform: translateX(-10%);
      transform: translateX(-10%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @media (min-width: 768px) {
    min-height: 480px;

    .work-section-content {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .work-section-content a {
      display: inline-block;
    }

    &:nth-of-type(even) .section-animation {
      display: flex;
    }
    &:nth-of-type(even) .work-section-content {
      order: 1 !important;
    }
    &:nth-of-type(even) .work-section-content.active {
      -webkit-animation-name: fadeInLeftCard;
      animation-name: fadeInLeftCard;
      animation-duration: 2s;
      transition: opacity 2.1s;
      opacity: 1;
    }
    &:nth-of-type(even) .work-section-img {
      order: 2 !important;
    }
    &:nth-of-type(even) .work-section-img.active {
      -webkit-animation-name: fadeInRightCard;
      animation-name: fadeInRightCard;
      animation-duration: 2s;
      transition: opacity 2.1s;
      opacity: 1;
    }
  }
  &:nth-of-type(even) .work-section-content {
    order: 2;
  }
  &:nth-of-type(even) .work-section-img {
    order: 1;
    padding-left: 8px;
    padding-right: 0px;
  }
  .hidden {
    opacity: 0;
  }
  .show {
    opacity: 1;
  }
  .height-500 {
    height: 500px;
  }
  .max-height-600 {
    max-height: 600px;
  }
  .max-width-440 {
    max-width: 440px;
  }
  @media (min-width: 992px) {
    .work-section-content {
      margin-left: 8.33333333%;
    }

    &:nth-of-type(even) .work-section-content {
      margin-left: 16.666666666%;
    }
    &:nth-of-type(even) {
      padding-left: 32px;
    }
    &:nth-of-type(odd) {
      padding-right: 32px;
    }
  }

  @media (max-width: 768px) {
    &:nth-of-type(even) .work-section-content {
      margin-left: 0px;
    }
    .work-section-content {
      margin-left: 0px;
    }
  }
  @media (max-width: 767px) {
    &:nth-of-type(odd) .work-section-content {
      margin-left: 8px;
    }
  }
`;

interface IWorkCardProps {
  image: string;
  title: string;
  technologies: string;
  text: string;
  link: string;
  buttonTitle: string;
  altText: string
}

const WorkCard = ({ image, title, technologies, text, link, buttonTitle, altText }: IWorkCardProps) => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    if (!inView && isVisible) {
      setInView(true);
    }
  }, [isVisible, inView]);

  return (
    <>
      <WorkCardStyled ref={ref} className="row">
        <div
          className={`max-height-600 my-3 mx-0 col-md-4 col-12 col-lg-5 work-section-img hidden ${
            inView ? 'active' : ''
          }`}
        >
          <Image src={image} alt={altText} type={ImageType.Fluid} className="max-height-600" />
        </div>
        <div
          className={`my-3 col-md-4 col-12 col-lg-5 work-section-content hidden ${
            inView ? 'active' : ''
          }`}
        >
          <div className="max-width-440">
            <Title 
            lineheightdesktop="33.6px"
            desktopsize="28px" 
            lineheightmobile="33.6px"
            mobilesize="28px" 
            tabletsize="28px"
            lineheighttablet="33.6px"
            color="#222">
              {title}
            </Title>
            <div className="work-icon-section">
              {technologies.split(',').map((icon, index) => (
                <SVGImage
                  key={index}
                  className="work-icon"
                  src={`icon-${icon.toLowerCase()}.svg`}
                  alt={`${icon} icon`}
                  height={24}
                  width={34}
                  title={icon}
                />
              ))}
            </div>
            <Text className="text-justify" size="14px">
              {text}
            </Text>
            <div className="align-self-start">
              <ButtonLink to={link} className="btn-purple mt-5" title={buttonTitle}>
                View project
              </ButtonLink>
            </div>
          </div>
        </div>
      </WorkCardStyled>
    </>
  );
};

export default WorkCard;
